import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import LogoHeader from './header/LogoHeader'

export default function ContactUs() {
  const navigate = useNavigate()
  const handleBackButton = (event) => {
    event.preventDefault()
    navigate('/homepage') // replace '/previous-url' with the desired URL
  }

  return (
    <>
      <LogoHeader />
      <div className="card m-3">
        <div className="card-header bg-white text-black" style={{ fontSize: '25px' }}>
          <b>Contact Us</b>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-5 mb-3" style={{ fontSize: '20px' }}>
              <b>Query Type</b>
            </div>
            <div className="col-sm-7" style={{ fontSize: '20px' }}>
              <b>How to Contact?</b>
            </div>
            <hr />
            <div className="col-sm-5 mt-2" style={{ fontSize: '18px' }}>
              Login or Registration related issues or any technical issues.
            </div>
            <div className="col-sm-7 mt-2" style={{ fontSize: '18px' }}>
              <a style={{ textDecoration: 'none' }}>
                <b>Please feel free to call us at the numbers provided.</b>
              </a>
              <br />
              <br />
              <b>IT & Technical (Form Filling)</b>
              <br />
              
              Phone - 9319251015 (For technical issues on all working days from 9 AM to 6 PM)
              <br />
              Email - onlinecounsellingadms@gmail.com
              <br />
              <br />
              <b>For Counselling Related Issues</b>
              <br />
              Email - 
              <a href="https://uhsr.ac.in/" style={{ textDecoration: 'none' }}>
                <b> academic@uhsr.ac.in.</b>
              </a>
              <br />
              Phone - 01262- (282113)
              <br />
              Email -
              <a href="https://dmer.haryana.gov.in/" style={{ textDecoration: 'none' }}>
                <b> dmer-hry@nic.in.</b>
              </a>
              <br />
              Phone - 0172- (2560799) , 0172-(22584633)
              <br />
              <br />
              {/* <span className="text-danger">
                    <b>
                      Please make sure you enter below details while raising the request
                    </b>
                  </span> */}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center" style={{ marginBottom: 100 }}>
        <button className="btn btn-success text-white" onClick={handleBackButton}>
          {'<<< Back'}
        </button>
      </div>

      {/* <Footer /> */}
    </>
  )
}
