import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { useRecoilState } from 'recoil'
import { useLocation } from 'react-router-dom'
import { globalStream } from 'src/atom/GlobalStream'
import { useNavigate } from 'react-router-dom'
import LogoHeader from 'src/components/header/LogoHeader'

const RegistrationValidation = () => {
  const [GSteam, setGlobalSteam] = useRecoilState(globalStream)
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const details = location.state?.det || {}

  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked)
  }

  const handleSubmit = () => {
    // Hide the buttons before printing
    const buttons = document.querySelectorAll('.btn')
    buttons.forEach((button) => {
      button.style.display = 'none'
    })

    // Trigger the print dialog
    window.print()

    // Show the buttons again after printing
    buttons.forEach((button) => {
      button.style.display = 'block'
    })
  }
  const handlefialSubmit = () => {
    navigate('/login')
  }

  return (
    <>
      <div className="container-fluid" style={{ padding: '10px 20px' }}>
        <div className="card">
          <LogoHeader />
        </div>

        <div
          className="mt-2 d-flex justify-content-center align-items-center"
          style={{ fontWeight: 'bold', fontSize: '25px' }}
        >
          Thanks for registering online for admission to {GSteam.counsellingName} Courses 2024-25.
        </div>

        <div className="card ms-5 me-5 mt-3">
          <div className="card-body">
            <div className="row">
              <div className="col-12 d-flex justify-content-strt">
                <th>Your Personal Details </th>
              </div>
            </div>
            <hr />
            <div className="mt-2">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <td>Full Name of Applicant :</td>
                    <td className="col-6">
                      <input
                        type="text"
                        className={`form-control  ? 'is-invalid' : ''}`}
                        id="name"
                        placeholder="Name of aplicant"
                        name="name"
                        value={details?.name}
                        //   onChange={handleInputChange}
                      />
                    </td>
                  </div>
                  <div className="col-6">
                    <td>Father Name :</td>
                    <td className="col-6">
                      <input
                        type="text"
                        className={`form-control  ? 'is-invalid' : ''}`}
                        id="fatherName"
                        placeholder="Father Name of aplicant "
                        name="fatherName"
                        value={details?.fatherName}
                        //   onChange={handleInputChange}
                      />
                    </td>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <td>Mother Name :</td>
                    <td className="col-6">
                      <input
                        type="text"
                        className={`form-control  ? 'is-invalid' : ''}`}
                        id="motherName"
                        placeholder="Mother Name"
                        name="motherName"
                        value={details?.motherName}
                        //   onChange={handleInputChange}
                      />
                    </td>
                  </div>
                  <div className="col-6">
                    <td>Date of Birth :</td>
                    <td className="col-6">
                      <input
                        type="text"
                        className={`form-control  ? 'is-invalid' : ''}`}
                        id="dob"
                        placeholder="Date of birth"
                        name="dob"
                        value={details?.dob}
                        // {details?.dob &&
                        //   moment(details?.dob).format('DD-MMM-YYYY')}
                        //   onChange={handleInputChange}
                      />
                    </td>
                  </div>
                </div>
                <hr />
                <div className=" d-flex flex-column align-items-left">
                  <label>
                    <th>Your Login Details</th>
                  </label>
                </div>
                <hr />
              </div>
            </div>
            <div className="mt-2">
              <div className="col-md-12">
                <div className="row justify-content-center">
                  <div className="col-md-3 d-flex align-items-center">
                    <label htmlFor="UserId" className="mr-2" style={{ width: 200 }}>
                      Login ID
                    </label>
                    <input
                      type="text"
                      className="form-control ms-4"
                      id="UserId"
                      style={{ marginLeft: '5px' }}
                      value={details?.userId}
                    />
                  </div>
                </div>

                <hr />
                <div className=" d-flex flex-column align-items-left" style={{ color: 'red' }}>
                  <label>
                    <tr>
                      Your login ID and password have been emailed and sent to you via SMS. Please
                      retain the login ID for all further communication related to your application.
                    </tr>
                    <tr>
                      Please take note that you will not be able to register again using the same
                      roll number.
                    </tr>
                  </label>
                  <ul>
                    <li>
                      Upon entering the login ID and one-time password correctly, you will be asked
                      to change the password mandatorily.
                    </li>
                    <li>
                      The candidate should keep these details confidential. They should not disclose
                      to any unauthorized person, UHSR and DMER will not be responsible.
                    </li>
                    <li>The candidate must keep a print out of this page.</li>
                  </ul>
                </div>
                <hr />

                <div className="text-center form-group d-flex justify-content-left">
                  <button
                    type="submit"
                    className="btn btn-sm text-white btn-info mx-2 mt-3 me-3"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Print'}
                  </button>

                  <button
                    type="submit"
                    className="btn btn-sm text-white btn-info mx-2 mt-3"
                    onClick={handlefialSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Procced to login'}
                  </button>
                </div>
                <label className="mt-3" style={{ color: 'red' }}>
                  <tr>
                    Disclaimer: DMER Haryana, UHSR Rohtak will not be responsible if your
                    login/password is misused by any unauthorized person except the candidate.
                  </tr>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
               @media print {
                .btn {
                  display: none !important;
                }
              }
                `}
      </style>
    </>
  )
}

export default RegistrationValidation
