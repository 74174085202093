import React, { useEffect, useState } from 'react'
import { loadingState } from 'src/atom/LoadingState'
import { useRecoilState } from 'recoil'
import { globalStream } from 'src/atom/GlobalStream'
import { useNavigate } from 'react-router-dom'
import { sendOtp } from 'src/services/PreRegistration/PreRegistration'
import LogoHeader from 'src/components/header/LogoHeader'

const RegistrationDetails = () => {
  const [GSteam, setGlobalSteam] = useRecoilState(globalStream)
  const registrationObject = {
    counsellingName:`${GSteam.counsellingName}`,
    rollno:`${GSteam.neetRollNumber}`,
    email:`${GSteam.email}`,
    mobileNumber:`${GSteam.mobileNumber}`
  }
 
  const [formData, setFormData] = useState(registrationObject)
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loader, enableLoader] = useRecoilState(loadingState)
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)



  const sendOtpAll = async () => {
     enableLoader(true)
     
    const fetchSendOtp = async () => {
      setIsSubmitting(true)
       enableLoader(true)
      var response = await sendOtp(formData)
      // console.log("response",response.body)
      if (response.success) {
        setIsSubmitting(false)
        const id = response.body.id
        // setFormData(response.body)
        navigate('/OTPScreen',  { state: {id } })
        
          enableLoader(false)
      } else {
        setIsSubmitting(false)
          enableLoader(false)
        alert(response.message)
      }
    }
    fetchSendOtp()
  }
  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked)
  }

  const handleSubmit = () => {
    sendOtpAll()
    
    
  }
  useEffect(() => {
   
    if (!formData.rollno) {
      
      navigate('/homepage')
    }
  }, [formData.neetRollNumber]); 
  const mobileNumber = formData?.mobileNumber;
const maskedNumber = mobileNumber.slice(0, -2).replace(/./g, '*') + mobileNumber.slice(-2);
const email = formData?.email;
const [username, domain] = email.split('@');
const maskedUsername = username.slice(0, 3) + username.slice(3).replace(/./g, '*');

const maskedEmail = maskedUsername + '@' + domain;

  return (
    <>
    <div className="container-fluid" style={{ padding: '10px 20px' }}>
      <div className="card">
       <LogoHeader/>
      </div>

      <div
        className="mt-2 d-flex justify-content-center align-items-center"
        style={{ fontWeight: 'bold', fontSize: '25px' }}
      >
        Online Application Form For Admission To {GSteam.counsellingName}
         {' '} Courses 2024-25
      </div>

      <div className="card ms-5 me-5 mt-3">
        <div className="card-body">
          <div className="row">
            <div className="col-12 d-flex justify-content-strt">
              <th>Verification of Communication Details</th>
            </div>
          </div>
          <hr />
          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="row">
                <div className="col">
                  <label htmlFor="Email">Email:</label>
                  <input 
                  type="text" 
                  className="form-control" 
                  id="Email"
                  value={maskedEmail} 
                  readOnly/>
                </div>
                <div className="col">
                  <label htmlFor="MobileNumber">Mobile Number:</label>
                  <input 
                  type="text" 
                  className="form-control" 
                  id="MobileNumber"
                   value={maskedNumber}
                   readOnly
                  />
                </div>
              </div>

              <div className="d-flex flex-column align-items-left" >
                <label style={{color:'red'}}>
                  <tr >
                  1. Same One Time Password (OTP) will be sent to the above mentioned email and mobile number.
                  </tr>
               <tr>
               2. Kindly make sure that mobile number is free from Do Not Disturb (DND) Facility.
               </tr>
               
                </label>
              
              </div>
              <hr />
              <div className="m-3 text-left form-group">
      
                <button
                    type="submit"
                    className="btn btn-sm btn-success text-white"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default RegistrationDetails
