import { atom } from 'recoil';

export const globalStream = atom({
  key: 'newStream',
  default: {
    counsellingName: '',
    card:'',
    course:'',
    neetRollNumber:'',
    email:'',
    mobileNumber:'',
  }
});
