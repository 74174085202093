export async function fetchAllCategories() {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + `/v1/api/candidate/fetchAllCategory`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function fetchArmedForceCategories() {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + '/v1/api/candidate/fetchArmForceCategory',
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function fetchArmedForceSubCategories(id) {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + `/v1/api/armForceSubCategory/${id}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function fetchNRIType() {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(process.env.REACT_APP_API + '/v1/api/candidate/fetchNriType', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function fetchNriCategory() {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + '/v1/api/candidate/fetchNriCategory',
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function fetchNriSubCategory(id) {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + `/v1/api/candidate/fetchNriSubCategory/${id}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function addRegisterationDetails(formData) {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + `/v1/api/candidate/registration-details`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function fetchRegisterationDetails() {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + '/v1/api/candidate/fetch/registration-details',
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}






export async function fetchAllSeats() {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + '/v1/api/candidate/getAllSeats',
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function fetchCandidatesMilestone() {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + '/v1/api/candidate/fetchCandidateMilestone',
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}
export async function fetchPaymentModesMaster(paymentMode) {
  const token = localStorage.getItem('token')
  const rawResponse = await fetch(
    process.env.REACT_APP_API + `/v1/api/fetch/fee/payment/modes?paymentType=${paymentMode}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  )
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}
