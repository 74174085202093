import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { loadingState } from 'src/atom/LoadingState'
import { useRecoilState } from 'recoil'
import {
  fetchAllCategories,
  fetchArmedForceCategories,
  addRegisterationDetails,
  fetchRegisterationDetails,
  fetchArmedForceSubCategories,
} from 'src/services/StudentRegisteration/StudentRegisterationServices'
import Tostify, { showToast } from '../../utils/Tostify'

const PGDNBRegisterationDetails = ({ onDetailsSubmit, nexttab, milestone }) => {
  const [formData, setFormData] = useState({
    smcRegistrationNumber: '',
    validUpto: '',
    hasHaryanaBonafide: false,
    pppId: '',
    muslimMinority: null,
    completedCourseInHaryana: null,
    category: { id: null },
    belongsToNonCreamy: false,
    pmsApplicable: false,
    aadharNumber: '',
    ewsCategory: null,
    belongsToPWBD: false,
    wardOfArmForce: false,
    armForceCategory: { id: null },
    armForceSubCategory: { id: null },
    grandchildOfFreedomFrightFromHaryana: false,
  })

  const [validationErrors, setValidationErrors] = useState({
    smcRegistrationNumber: '',
    validUpto: '',
    hasHaryanaBonafide: '',
    pppId: '',
    muslimMinority: '',
    completedCourseInHaryana: '',
    category: '',
    ewsCategory: '',
    belongsToNonCreamy: '',
    pmsApplicable: '',
    aadharNumber: '',
    belongsToPWBD: '',
    wardOfArmForce: '',
    armForceCategory: '',
    armForceSubCategory: '',
    grandchildOfFreedomFrightFromHaryana: '',
    armForceSubCategoryId: '',
  })

  const validateForm = () => {
    let errors = {}
    let isValid = true

    if (formData.smcRegistrationNumber === '' || formData.smcRegistrationNumber === null) {
      errors.smcRegistrationNumber = 'Please provide Registration number.'
      isValid = false
    }
    if (!formData.validUpto) {
      errors.validUpto = 'Please select Registration valid upto date.'
      isValid = false
    }

    if (formData.muslimMinority === null) {
      errors.muslimMinority = 'Please select whether you belong to Muslim minority or not.'
      isValid = false
    }
    if (formData.completedCourseInHaryana === null) {
      errors.completedCourseInHaryana =
        'Please select whether you completed course in haryana or not.'
      isValid = false
    }

    if (formData.hasHaryanaBonafide === true) {
      if (formData.belongsToPWBD === null) {
        errors.belongsToPWBD = 'Please select whether you applying under PWBD category.'
        isValid = false
      }
      if (formData.wardOfArmForce === null) {
        errors.wardOfArmForce = 'Please select whether are you ward of armed forces personnel.'
        isValid = false
      }
      if (formData.grandchildOfFreedomFrightFromHaryana === null) {
        errors.grandchildOfFreedomFrightFromHaryana =
          'Please select whether are you grand child of freedom fighter from Haryana.'
        isValid = false
      }
      if (!formData.category?.id) {
        errors.category = 'Please select category.'
        isValid = false
      }
    }

    if (Weaker.includes(category) && formData.ewsCategory === undefined) {
      errors.ewsCategory = 'Please select whether you qualify for EWS Category.'
      isValid = false
    }

    if (
      creamy.includes(category) &&
      (formData.belongsToNonCreamy === undefined || formData.belongsToNonCreamy === null)
    ) {
      errors.belongsToNonCreamy = 'Please select whether you belong to Non-Creamy.'
      isValid = false
    }
    if (
      creamy.includes(category) &&
      (formData.pmsApplicable === undefined || formData.belongsToNonCreamy === null)
    ) {
      errors.pmsApplicable = 'Please select whether pms aplicable.'
      isValid = false
    }

    if (
      filteredCategories.includes(category) &&
      (formData.pmsApplicable === undefined || formData.pmsApplicable === null)
    ) {
      errors.pmsApplicable = 'Please select whether pms aplicable.'
      isValid = false
    }
    if (formData.wardOfArmForce === null) {
      errors.wardOfArmForce = 'Please select whether you are a ward of armed forces personnel.';
      isValid = false;
    } else if (formData.wardOfArmForce === true) {
      if (!formData.armForceCategory || !formData.armForceCategory.id || formData.armForceCategory.id ===null || formData.armForceCategory.id ==="" ) {
        errors.armForceCategory = 'Please select the armed forces category.';
        isValid = false;
      }
      if(armSubCategories.length > 0){
      if (formData.armForceSubCategory ===undefined || !formData.armForceSubCategory.id || formData.armForceSubCategory.id === null || formData.armForceSubCategory.id === "" ) {
        errors.armForceSubCategory = 'Please select the armed forces sub-category.';
        isValid = false;
      }
    }
    }


    setValidationErrors(errors)
    return isValid
  }

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [loader, enableLoader] = useRecoilState(loadingState)
  const [category, setCategory] = useState([])
  const [defaultCategory, setDefaultCategory] = useState([])
  const [selectedArmyCategory, setSelectedArmyCategory] = useState([])
  const [filteredCategories, setFilteredCategories] = useState([])
  const [creamy, setCreamy] = useState([])
  const [ArmyCategory, setArmyCategory] = useState([])
  const [Weaker, setWeaker] = useState([])
  const [categories, setCategories] = useState([])
  const [armCategories, setArmCategories] = useState([])
  const [armSubCategories, setArmSubCategories] = useState([])

  useEffect(() => {
    fetchAllCategoriesApiCall()
    fetchArmForceCategoryApiCall()
    //  fetchArmForceSubCategoryApiCall()
    fetchRegisterationDetailsApiCall()
  }, [])

  const handleCategoryChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10)
    setCategory(selectedValue)

    const { name, value } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: { id: value },
      pmsApplicable: null,
      belongsToNonCreamy: null,
    }))
  }

  const fetchAllCategoriesApiCall = async () => {
    enableLoader(true)
    try {
      const response = await fetchAllCategories()
      if (response.success) {
        setCategories(response.body)
        const generalObject = response.body.find((item) => item.name === 'GENERAL')
        const targetNames = ['BCA', 'BCB', 'SC', 'SC-Deprived']
        const filteredIds = response.body
          .filter((category) => targetNames.includes(category.name))
          .map((category) => category.id)
        setDefaultCategory(generalObject?.id)
        setFilteredCategories(filteredIds)
        const names = ['BCA', 'BCB']
        const filterIds = response.body
          .filter((category) => names.includes(category.name))
          .map((category) => category.id)
        setCreamy(filterIds)
        const EwsNames = ['EWS']
        const EWSIds = response.body
          .filter((category) => EwsNames.includes(category.name))
          .map((category) => category.id)
        setWeaker(EWSIds)
      }
    } catch (error) {
      console.error('Error fetching categories:', error)
    } finally {
      enableLoader(false)
    }
  }

  const fetchArmForceCategoryApiCall = async () => {
    enableLoader(true)
    var fetchArmCategories = async () => {
      var response = await fetchArmedForceCategories()
      if (response.success) {
        setArmCategories(response.body)

        const namesPrefix = 'Priority-V:'
        const filterIds = response.body
          .filter((category) => category.categoryName?.startsWith(namesPrefix))
          .map((category) => category.id)
        setArmyCategory(filterIds)
        enableLoader(false)
      } else {
        enableLoader(false)
      }
    }
    fetchArmCategories()
  }

  const fetchArmForceSubCategoryApiCall = async (id) => {
    var fetchArmCategories = async () => {
      var response = await fetchArmedForceSubCategories(id)
      if (response.success) {
        setArmSubCategories(response.body);
        if(response.body){
          setFormData((prevFormData) => ({
            ...prevFormData,
            armForceSubCategory: response.body.find(
              (subCategory) => subCategory.id === prevFormData.armForceSubCategory?.id
            ),
          }))
        }
      } 
    }
    fetchArmCategories()
  }

  const fetchRegisterationDetailsApiCall = async () => {
    enableLoader(true)
    try {
      const response = await fetchRegisterationDetails()
      if (response?.success) {
        if (response.body !== null) {
          setFormData(response.body)
          
          if (response.body?.armForceCategory?.id) {
            await fetchArmForceSubCategoryApiCall(response.body.armForceCategory.id)
          }
        }
      }
    } catch (error) {
      console.error('Error fetching registration details:', error)
    } finally {
      enableLoader(false)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        [name]: value,
      }
      return updatedDetails
    })
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }))
  }

  const handleInputArmForceChange = (e) => {
    const { name, value } = e.target
    const selectedValue = parseInt(value, 10);
    setSelectedArmyCategory(selectedValue)
    setFormData((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        [name]: { id: value },
        armForceSubCategory: { id: null },
      }

      return updatedDetails
    })
    validationErrors.armForceCategory='';
    validationErrors.armForceSubCategory='';
    if(selectedValue !=null && selectedValue !="" && !isNaN(selectedValue)){
      fetchArmForceSubCategoryApiCall(selectedValue)
    }
  }
  const handleInputArmForceSubChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        [name]: { id: value },
      }
      return updatedDetails
    })
    validationErrors.armForceSubCategory='';
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Validate the form
    const isValid = validateForm()
    if (!isValid) return

    // Clear validation errors and set loader states
    setValidationErrors({})
    setIsSubmitted(true)

    try {
      // Submit the registration details
      const response = await addRegisterationDetails(formData)

      if (response.success) {
        onDetailsSubmit(nexttab)
      } else {
        // Handle submission failure
        // Uncomment and handle alert visibility as needed
        // setAlertMessage(response.message);
        // setAlertVisible(true);
        showToast(response.message, 'error')
      }
    } catch (error) {
      // console.error('Error submitting registration details:', error)
      showToast(error, 'error')
      // Handle the error (e.g., show a generic error message to the user)
    } finally {
      setIsSubmitted(false)
    }
  }
  const handleHaryanaBonafiedChange = (e) => {
    const { name, value } = e.target

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value === 'true' ? true : false }

      if (value === 'false') {
        setCategory([0])
        updatedFormData.belongsToPWBD = false
        updatedFormData.wardOfArmForce = false
        updatedFormData.aadharNumber = ''

        if (updatedFormData.armForceCategory) {
          updatedFormData.armForceCategory.id = null
          updatedFormData.armForceSubCategory = null
        }
        updatedFormData.grandchildOfFreedomFrightFromHaryana = false
        updatedFormData.pppId = ''
        if (updatedFormData.category) {
          updatedFormData.category.id = defaultCategory
        }
        updatedFormData.belongsToNonCreamy = null
      } else {
        if (updatedFormData.category) {
          updatedFormData.category.id = ''
        }
        updatedFormData.belongsToPWBD = null
        updatedFormData.wardOfArmForce = null
        updatedFormData.armForceCategory = { id: null }
        updatedFormData.armForceSubCategory = null
        updatedFormData.grandchildOfFreedomFrightFromHaryana = null
        updatedFormData.pppId = null
        updatedFormData.belongsToNonCreamy = null
        updatedFormData.aadharNumber = ''
      }

      return updatedFormData
    })
  }

  const handleRadioChange5 = (e) => {
    const { name, value } = e.target

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value === 'true' ? true : false }

      // Clear errors for NRI-related fields when the user interacts with the radio buttons
      if (name === 'nri') {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          nriType: '',
          nriCategory: '',
          nriSubCategory: '',
        }))
        if (value === 'false') {
          setFormData((prevState) => ({
            ...prevState,
            nriType: { id: null },
            nriCategory: { id: null }, // Clear NRI category
            nriSubCategory: { id: null }, // Clear NRI subcategory
          }))

        }
      }
      if (name === 'wardOfArmForce') {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          armForceCategory: '',
          armForceSubCategory: '',
        }))
        if (value === 'false') {
          setFormData((prevState) => ({
            ...prevState,
            armForceCategory: { id: null }, // Clear NRI category
            armForceSubCategory: { id: null }, // Clear NRI subcategory
          }))

        }
        setSelectedArmyCategory()
      }

      return updatedFormData
    })

    // Clear validation error when the user interacts with the radio buttons
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
  }

  const handleNonCreamy = (e) => {
    const selectedValue = e.target.value === 'true';
    setFormData({ ...formData, belongsToNonCreamy: selectedValue });

    if (selectedValue) {
      showToast(
        'I have read the Haryana Govt Notification dated 17.11.2021 issued by Welfare of Scheduled Castes and Backward Classes Department','info'
         // Assuming showToast can accept a color parameter
      );
    } else {
      showToast(
        'You are not eligible for seats under BCA/BCB quota as per conditions laid out in Haryana Govt Notification dated 17-11-2021 issued by Welfare of Scheduled Castes and Backward Classes Department However you will be considered under General category','info'
         // Assuming showToast can accept a color parameter
      );
    }
  };

 

  const handleAadharNumber = (e) => {
    const isDigit = /^\d$/.test(e.key)
    const currentValue = e.target.value
    const isMaxLength = currentValue.length >= 12

    // Allow digits if less than 12 and input is a digit, or allow backspace
    const isValidInput = (isDigit && !isMaxLength) || e.key === 'Backspace'

    if (!isValidInput) {
      e.preventDefault()
    }
  }

  const RollNumber = (e) => {
    const isValidInput = /^\d$/.test(e.key)
    const currentInputLength = e.target.value.length
    const isMaxLengthReached = currentInputLength >= 18

    if (!isValidInput || isMaxLengthReached) {
      e.preventDefault()
    }
  }

  const handleParivarPehchanId = (e) => {
    const isNotSpace = e.key !== ' '

    if (!isNotSpace) {
      e.preventDefault()
    }
  }

  let LocalCourseName = localStorage.getItem('counsellingName')
  let courseName
  let courseType
  if (LocalCourseName === 'MD') {
    courseName = 'Medical'
    courseType = 'MCI/NMC '
  }
  if (LocalCourseName === 'MDS') {
    courseName = 'Dental '
    courseType = 'DCI '
  }
  if (LocalCourseName === 'PGDNB') {
    courseName = 'Post Diploma DNB'
  }

  return (
    <>
      {!loader && (
        <div className="card">
          <div className="card-header text-white" style={{ backgroundColor: '#3C4B64' }}>
            <div className="d-flex align-items-center justify-content-between">
              <span className="mr-auto">Registration Details</span>
            </div>
          </div>
         
          <div className="card-body">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" colSpan="2" style={{ textAlign: 'center' }}>
                    Registration Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                    State {courseName} Council {courseType} Registration No.
                    <span style={{ color: 'red' }}>*</span>
                  </th>
                  <td>
                    <input
                      type="text"
                      className={`form-control ${
                        validationErrors.smcRegistrationNumber ? 'is-invalid' : ''
                      }`}
                      id="smcRegistrationNumber"
                      placeholder="Enter Registration number"
                      name="smcRegistrationNumber"
                      style={{ width: '50%', height: '30px' }}
                      value={formData?.smcRegistrationNumber}
                      onChange={handleInputChange}
                      onKeyPress={RollNumber}
                    />
                    {validationErrors.smcRegistrationNumber && (
                      <div className="invalid-feedback">
                        {validationErrors.smcRegistrationNumber}
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                    State {courseName} Council {courseType} Registration Valid Upto.
                    <span style={{ color: 'red' }}>*</span>
                  </th>
                  <td>
                    <input
                      type="date"
                      className={`form-control ${validationErrors.validUpto ? 'is-invalid' : ''}`}
                      id="validUpto"
                      name="validUpto"
                      style={{ width: '50%', height: '30px' }}
                      value={formData?.validUpto}
                      onChange={handleInputChange}
                    />
                    {validationErrors.validUpto && (
                      <div className="invalid-feedback">{validationErrors.validUpto}</div>
                    )}
                  </td>
                </tr>
                <tr>
                  
                  <td  style={{ width: '50%', textAlign: 'right' }}>
                    <p style={{ margin: '0' }}>
                      <b>
                        Are You a Haryana Bonafide Resident ?<span style={{ color: 'red' }}>*</span>
                      </b>
                    </p>
                    <p style={{ margin: '0', color: 'blue' }}>
                      (Candidate with Haryana Bonafide Resident Certificate)
                    </p>
                  </td>
                  
                  <td>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasHaryanaBonafide"
                      id="haryanaResidentYes"
                      value={true}
                      onChange={(e) => handleHaryanaBonafiedChange(e)}
                      checked={formData?.hasHaryanaBonafide === true}
                    />
                    <label className="form-check-label ms-1" htmlFor="haryanaResidentYes">
                      Yes
                    </label>
                    <input
                      className="form-check-input ms-3"
                      type="radio"
                      name="hasHaryanaBonafide"
                      id="haryanaResidentNo"
                      value={false}
                      onChange={(e) => handleHaryanaBonafiedChange(e)}
                      checked={formData?.hasHaryanaBonafide === false}
                    />
                    <label className="form-check-label ms-1" htmlFor="haryanaResidentNo">
                      No
                    </label>
                  </td>
                </tr>
                {formData?.hasHaryanaBonafide && (
                  <tr>
                    <td scope="row" style={{ width: '50%', textAlign: 'right' }}>
                      <p style={{ margin: '0' }}>
                        <b>Parivar Pehchan Patra Family ID (if available)</b>
                      </p>
                      <p style={{ margin: '0', color: 'blue' }}>
                        (Mandatory only if qualifying for Post Matric Scholarship Scheme)
                      </p>
                    </td>
                    <td>
                      <input
                        type="text"
                        className={`form-control ${validationErrors.pppId ? 'is-invalid' : ''}`}
                        id="pppId"
                        name="pppId"
                        style={{ width: '50%', height: '30px' }}
                        placeholder="Enter PPP ID "
                        value={formData?.pppId}
                        onChange={handleInputChange}
                        onKeyPress={handleParivarPehchanId}
                      />
                      {validationErrors.pppId && (
                        <div className="invalid-feedback">{validationErrors.pppId}</div>
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                    Do you belong to muslim minority ?<span style={{ color: 'red' }}>*</span>
                  </th>
                  <td>
                    <input
                      className={`form-check-input ${
                        validationErrors.muslimMinority ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="muslimMinority"
                      id="exampleRadio3"
                      value={true}
                      checked={formData?.muslimMinority === true}
                      onChange={(e) => handleRadioChange5(e)}
                    />
                    <label className="form-check-label ms-1" htmlFor="exampleRadio3">
                      Yes
                    </label>
                    <input
                      className={`form-check-input ms-3 ${
                        validationErrors.muslimMinority ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="muslimMinority"
                      id="exampleRadio4"
                      value={false}
                      checked={formData?.muslimMinority === false}
                      onChange={(e) => handleRadioChange5(e)}
                    />
                    <label className="form-check-label ms-1" htmlFor="exampleRadio4">
                      No
                    </label>
                    {validationErrors.muslimMinority && (
                      <div className="invalid-feedback">{validationErrors.muslimMinority}</div>
                    )}
                  </td>
                </tr>
                {/* <Modal show={showPopup} onHide={handleClosePopup}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  {showTable ? (
                    <p>
                      I have read the Haryana Govt Notification dated 17.11.2021 issued by Welfare
                      of Scheduled Castes and Backward Classes Department
                    </p>
                  ) : (
                    <p>
                      You are not eligible for seats under BCA/BCB quota as per conditions laid out
                      in Haryana Govt Notification dated 17-11-2021 issued by Welfare of Scheduled
                      Castes and Backward Classes Department However you will be considered under
                      General category
                    </p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClosePopup}>
                    OK
                  </Button>
                </Modal.Footer>
              </Modal> */}
              </tbody>
            </table>
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" colSpan="2" style={{ textAlign: 'center' }}>
                    Other Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                    Have You completed MBBS course from any college situated in Haryana ?
                    <span style={{ color: 'red' }}>*</span>
                  </th>
                  <td>
                    <input
                      className={`form-check-input ${
                        validationErrors.completedCourseInHaryana ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="completedCourseInHaryana"
                      id="examplesRadio3"
                      value={true}
                      checked={formData?.completedCourseInHaryana === true}
                      onChange={(e) => handleRadioChange5(e)}
                    />
                    <label className="form-check-label ms-1" htmlFor="examplesRadio3">
                      Yes
                    </label>
                    <input
                      className={`form-check-input ms-3 ${
                        validationErrors.completedCourseInHaryana ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="completedCourseInHaryana"
                      id="examplesRadio4"
                      value={false}
                      checked={formData?.completedCourseInHaryana === false}
                      onChange={(e) => handleRadioChange5(e)}
                    ></input>
                    <label className="form-check-label ms-1" htmlFor="examplesRadio4">
                      No
                    </label>
                    {validationErrors.completedCourseInHaryana && (
                      <div className="invalid-feedback">
                        {validationErrors.completedCourseInHaryana}
                      </div>
                    )}
                  </td>
                </tr>

                <tr>
                  <td scope="row" style={{ width: '50%', textAlign: 'right' }}>
                    <p style={{ margin: '0' }}>
                      <b>
                        Category<span style={{ color: 'red' }}>*</span>
                      </b>
                    </p>
                    <p style={{ margin: '0', color: 'blue' }}>
                      (For Registration-cum-Counselling fee purposes only)
                    </p>
                  </td>
                  <td>
                    <select
                      name="category"
                      className={`form-control ${validationErrors.category ? 'is-invalid' : ''}`}
                      id="category"
                      value={formData?.category?.id}
                      disabled={formData?.hasHaryanaBonafide === false}
                      onChange={handleCategoryChange}
                      style={{ width: '50%', height: '35px' }}
                    >
                      <option value="">Select Category...</option>
                      {categories?.map((cat) => (
                        <option key={cat.id} value={cat.id}>
                          {cat.name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.category && (
                      <div className="invalid-feedback">{validationErrors.category}</div>
                    )}
                  </td>
                </tr>

                {(filteredCategories.includes(+category) ||
                  (filteredCategories.includes(formData.category?.id) &&
                    formData.pmsApplicable)) && (
                  <tr>
                    <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                      Do you qualify for Post Matric Scholarship Scheme ?
                      <span style={{ color: 'red' }}>*</span>
                    </th>
                    <td>
                      <input
                        className={`form-check-input ${
                          validationErrors.pmsApplicable ? 'is-invalid' : ''
                        }`}
                        type="radio"
                        name="pmsApplicable"
                        id="eXampLesRadio3"
                        value={true}
                        checked={formData?.pmsApplicable === true}
                        onChange={(e) => handleRadioChange5(e)}
                        disabled={formData?.hasHaryanaBonafide === false}
                      />
                      <label className="form-check-label ms-1" htmlFor="eXampLesRadio3">
                        Yes
                      </label>
                      <input
                        className={`form-check-input ms-3 ${
                          validationErrors.pmsApplicable ? 'is-invalid' : ''
                        }`}
                        type="radio"
                        name="pmsApplicable"
                        id="eXampLesRadio4"
                        value={false}
                        checked={formData?.pmsApplicable === false}
                        onChange={(e) => handleRadioChange5(e)}
                        disabled={formData?.hasHaryanaBonafide === false}
                      ></input>
                      <label className="form-check-label ms-1" htmlFor="eXampLesRadio4">
                        No
                      </label>
                      {validationErrors.pmsApplicable && (
                        <div className="invalid-feedback">{validationErrors.pmsApplicable}</div>
                      )}
                    </td>
                  </tr>
                )}

                {(Weaker.includes(+category) ||
                 (Weaker.includes(formData.category?.id) && formData.ewsCategory)) && (
                  <tr>
                    <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                      Do you qualify all the required criteria for EWS category as per Haryana Govt.
                      Notification dated 25-02-2019 ?<span style={{ color: 'red' }}>*</span>
                    </th>
                    <td>
                      <input
                        className={`form-check-input ${
                          validationErrors.ewsCategory ? 'is-invalid' : ''
                        }`}
                        type="radio"
                        name="ewsCategory"
                        id="ewsCategory"
                        value={true}
                        checked={formData?.ewsCategory === true}
                        onChange={(e) => handleRadioChange5(e)}
                      />
                      <label className="form-check-label ms-1" htmlFor="EXamPLesRadio3">
                        Yes
                      </label>
                      <input
                        className={`form-check-input ms-3 ${
                          validationErrors.ewsCategory ? 'is-invalid' : ''
                        }`}
                        type="radio"
                        name="ewsCategory"
                        id="ewsCategory"
                        value={false}
                        checked={formData?.ewsCategory === false}
                        onChange={(e) => handleRadioChange5(e)}
                      ></input>
                      <label className="form-check-label ms-1" htmlFor="EXamPLesRadio4">
                        No
                      </label>
                      {validationErrors.ewsCategory && (
                        <div className="invalid-feedback">{validationErrors.ewsCategory}</div>
                      )}
                    </td>
                  </tr>
                )}
                {(creamy.includes(+category) ||
                  (creamy.includes(formData.category?.id) && formData.belongsToNonCreamy)) && (
                  <tr>
                    <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                      Are you eligible for admissions under BCA/BCB category and fall under
                      Non-Creamy layer as per Haryana Govt. Notification dated 17.11.2021 ?
                      <span style={{ color: 'red' }}>*</span>
                    </th>
                    <td>
                      <input
                        className={`form-check-input ${
                          validationErrors.belongsToNonCreamy ? 'is-invalid' : ''
                        }`}
                        type="radio"
                        name="belongsToNonCreamy"
                        id="EXamPLesRadio3"
                        value={true}
                        checked={formData?.belongsToNonCreamy === true}
                        onChange={handleNonCreamy}
                      />
                      <label className="form-check-label ms-1" htmlFor="EXamPLesRadio3">
                        Yes
                      </label>
                      <input
                        className={`form-check-input ms-3 ${
                          validationErrors.belongsToNonCreamy ? 'is-invalid' : ''
                        }`}
                        type="radio"
                        name="belongsToNonCreamy"
                        id="EXamPLesRadio4"
                        value={false}
                        checked={formData?.belongsToNonCreamy === false}
                        onChange={handleNonCreamy}
                      ></input>
                      <label className="form-check-label ms-1" htmlFor="EXamPLesRadio4">
                        No
                      </label>
                      {validationErrors.belongsToNonCreamy && (
                        <div className="invalid-feedback">
                          {validationErrors.belongsToNonCreamy}
                        </div>
                      )}
                    </td>
                  </tr>
                )}

                <tr>
                  <th scope="row" style={{ textAlign: 'right', width: '50%' }}>
                    Aadhar Number
                  </th>
                  <td>
                    <input
                      type="text"
                      className={`form-control ${
                        validationErrors.aadharNumber ? 'is-invalid' : ''
                      }`}
                      id="aadharNumber"
                      placeholder="Enter aadhar number"
                      name="aadharNumber"
                      style={{ width: '50%', height: '30px' }}
                      value={formData?.aadharNumber}
                      onChange={handleInputChange}
                      onKeyPress={handleAadharNumber}
                    />
                    {validationErrors.aadharNumber && (
                      <div className="invalid-feedback">{validationErrors.aadharNumber}</div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td scope="row" style={{ width: '50%', textAlign: 'right' }}>
                    <p style={{ margin: '0' }}>
                      <b>
                        Are you applying under PWBD category ?
                        <span style={{ color: 'red' }}>*</span>
                      </b>
                    </p>
                    <p style={{ margin: '0', color: 'blue' }}>
                      (As per Govt. of India notification)
                    </p>
                  </td>
                  <td>
                    <input
                      className={`form-check-input ${
                        validationErrors.belongsToPWBD ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="belongsToPWBD"
                      id="EXamplesRadio3"
                      value={true}
                      checked={formData?.belongsToPWBD === true}
                      onChange={(e) => handleRadioChange5(e)}
                      disabled={formData?.hasHaryanaBonafide === false}
                    />
                    <label className="form-check-label ms-1" htmlFor="EXamplesRadio3">
                      Yes
                    </label>
                    <input
                      className={`form-check-input ms-3 ${
                        validationErrors.belongsToPWBD ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="belongsToPWBD"
                      id="EXamplesRadio4"
                      value={false}
                      checked={formData?.belongsToPWBD === false}
                      onChange={(e) => handleRadioChange5(e)}
                      disabled={formData?.hasHaryanaBonafide === false}
                    ></input>
                    <label className="form-check-label ms-1" htmlFor="EXamplesRadio4">
                      No
                    </label>
                    {validationErrors.belongsToPWBD && (
                      <div className="invalid-feedback">{validationErrors.belongsToPWBD}</div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                    Are you ward of Armed Forces personnel ?<span style={{ color: 'red' }}>*</span>
                  </th>
                  <td>
                    <input
                      className={`form-check-input ${
                        validationErrors.wardOfArmForce ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="wardOfArmForce"
                      id="eXAmplesRadio3"
                      value={true}
                      checked={formData?.wardOfArmForce === true}
                      onChange={(e) => handleRadioChange5(e)}
                      disabled={formData?.hasHaryanaBonafide === false}
                    />
                    <label className="form-check-label ms-1" htmlFor="eXAmplesRadio3">
                      Yes
                    </label>
                    <input
                      className={`form-check-input ms-3 ${
                        validationErrors.wardOfArmForce ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="wardOfArmForce"
                      id="eXAmplesRadio4"
                      value={false}
                      checked={formData?.wardOfArmForce === false}
                      onChange={(e) => handleRadioChange5(e)}
                      disabled={formData?.hasHaryanaBonafide === false}
                    ></input>
                    <label className="form-check-label ms-1" htmlFor="eXAmplesRadio4">
                      No
                    </label>
                    {validationErrors.wardOfArmForce && (
                      <div className="invalid-feedback">{validationErrors.wardOfArmForce}</div>
                    )}
                  </td>
                </tr>
                {formData?.wardOfArmForce === true && (
                  <tr>
                    <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                      Select Armed Force Category<span style={{ color: 'red' }}>*</span>
                    </th>
                    <td>
                      <select
                        name="armForceCategory"
                        className={`form-control ${
                          validationErrors.armForceCategory ? 'is-invalid' : ''
                        }`}
                        id="armForceCategory"
                        value={formData?.armForceCategory?.id}
                        onChange={handleInputArmForceChange}
                        style={{ width: '50%', height: '35px' }}
                      >
                        <option value={null}>Select an option...</option>
                        {armCategories?.map((arm) => (
                          <option key={arm.id} value={arm.id}>
                            {arm?.categoryName}
                          </option>
                        ))}
                      </select>
                      {validationErrors.armForceCategory && (
                        <div className="invalid-feedback">{validationErrors.armForceCategory}</div>
                      )}
                    </td>
                  </tr>
                )}

                {(ArmyCategory.includes(selectedArmyCategory) ||
                  (formData?.wardOfArmForce === true && formData.armForceSubCategory?.id && armSubCategories.length>0)) && (
                  <tr>
                    <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                      Select SubCategory of Armed Force<span style={{ color: 'red' }}>*</span>
                    </th>
                    <td>
                      <select
                        name="armForceSubCategory"
                        className={`form-control ${
                          validationErrors.armForceSubCategory ? 'is-invalid' : ''
                        }`}
                        id="armForceSubCategory"
                        value={formData?.armForceSubCategory?.id}
                        onChange={handleInputArmForceSubChange}
                        style={{ width: '50%', height: '35px' }}
                      >
                        <option value={null}>Select an option...</option>
                        {armSubCategories?.map((arm) => (
                          <option key={arm.id} value={arm.id}>
                            {arm.subCategoryName}
                          </option>
                        ))}
                      </select>
                      {validationErrors.armForceSubCategory && (
                        <div className="invalid-feedback">
                          {validationErrors.armForceSubCategory}
                        </div>
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <th scope="row" style={{ width: '50%', textAlign: 'right' }}>
                    Are you grand child of Freedom Fighter from Haryana ?
                    <span style={{ color: 'red' }}>*</span>
                  </th>
                  <td>
                    <input
                      className={`form-check-input ${
                        validationErrors.grandchildOfFreedomFrightFromHaryana ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="grandchildOfFreedomFrightFromHaryana"
                      id="eXamplesRadio3"
                      value={true}
                      checked={formData?.grandchildOfFreedomFrightFromHaryana === true}
                      onChange={(e) => handleRadioChange5(e)}
                      disabled={formData?.hasHaryanaBonafide === false}
                    />
                    <label className="form-check-label ms-1" htmlFor="eXamplesRadio3">
                      Yes
                    </label>
                    <input
                      className={`form-check-input ms-3 ${
                        validationErrors.grandchildOfFreedomFrightFromHaryana ? 'is-invalid' : ''
                      }`}
                      type="radio"
                      name="grandchildOfFreedomFrightFromHaryana"
                      id="eXamplesRadio4"
                      value={false}
                      checked={formData?.grandchildOfFreedomFrightFromHaryana === false}
                      onChange={(e) => handleRadioChange5(e)}
                      disabled={formData?.hasHaryanaBonafide === false}
                    ></input>
                    <label className="form-check-label ms-1" htmlFor="eXamplesRadio4">
                      No
                    </label>
                    {validationErrors?.grandchildOfFreedomFrightFromHaryana && (
                      <div className="invalid-feedback">
                        {validationErrors?.grandchildOfFreedomFrightFromHaryana}
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <button
              type="submit"
              className="btn btn-sm text-white btn-info d-block mx-auto mt-3"
              onClick={handleSubmit}
              disabled={isSubmitted}
            >
              {isSubmitted ? 'Submitting...' : 'Proceed to fill / Edit Form'}
            </button>
          </div>
        </div>
      )}
      <Tostify />
    </>
  )
}
PGDNBRegisterationDetails.propTypes = {
  onDetailsSubmit: PropTypes?.func,
  nexttab: PropTypes?.string,
  milestone: PropTypes?.number,
}

export default PGDNBRegisterationDetails
