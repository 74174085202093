import React, { Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './scss/style.scss'
import { RecoilRoot } from 'recoil'
import RegistrationDetails from './views/pages/Home/RegestrationDetails'
import OtpScreen from './views/pages/Home/OTPScreen'
import RegistrationValidation from './views/pages/Home/RegestrationValidate'
import ForgotPassword from './views/pages/Home/ForgotPassword'
import ValidatePassword from './views/pages/Home/ValidatePassword'
import PGDNBRegisterationDetails from './components/StudentRegisteration/PGDNBRegisterationDetails'
import ConfirmNewPassword from './views/pages/Home/confirmNewPassword'
import FirstTimeChangePassword from './views/pages/changePassword/FirstTimeChangePassword'
import ScrollToTop from './utils/ScrollToTop'
import ContactUs from './components/ContactUs'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Register = React.lazy(() => import('./views/pages/registration/registration'))
const Homepage = React.lazy(() => import('./views/pages/Home/Homepage'))
const RegistrationInstruction = React.lazy(() =>
  import('./views/pages/Home/RegistrationInstruction'),
)
const OnboardingRegistration = React.lazy(() => import('./views/pages/Home/OnboardingRegistration'))

function App() {
  
  return (
    <Router>
    
        <ScrollToTop />
        <Suspense fallback={loading}>
          <RecoilRoot>
            <Routes>
              <Route exact path="/" element={<Homepage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/404" element={<Page404 />} />
              <Route path="/500" element={<Page500 />} />
              <Route path="/registration" element={<Register />} />
              <Route path="/homepage" element={<Homepage />} />
              <Route path="/RegistrationInstruction" element={<RegistrationInstruction />} />
              <Route path="/onboardingRegistration" element={<OnboardingRegistration />} />
              <Route path="/RegistrationDetails" element={<RegistrationDetails />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/ValidatePassword" element={<ValidatePassword />} />
              <Route path="/ConfirmNewPassword" element={<ConfirmNewPassword />} />
              <Route path="/RegistrationValidation" element={<RegistrationValidation />} />
              <Route path="/PGDNBRegistrationDetails" element={<PGDNBRegisterationDetails />} />
              <Route path="/OTPScreen" element={<OtpScreen />} />
              <Route path="/ChangePassword" element={<FirstTimeChangePassword />} />
              <Route path="/contact-us" element={<ContactUs/>} />
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          </RecoilRoot>
        </Suspense>

    </Router>
  )
}

export default App
